import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { SupplyColors } from '../../constants';

const Dot = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: 50%;

  & div {
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    height: ${primitives.layout.size5}px;
    justify-content: center;
    width: ${primitives.layout.size5}px;
  }

  &.tricolor div {
    height: ${primitives.layout.size2}px;
    width: ${primitives.layout.size2}px;
    box-sizing: initial;
  }

  &.cyan div {
    background-color: ${SupplyColors.CYAN};
  }

  &.magenta div {
    background-color: ${SupplyColors.MAGENTA};
  }

  &.yellow div {
    background-color: ${SupplyColors.YELLOW};
  }

  &.black div {
    background-color: ${SupplyColors.BLACK};
    color: ${primitives.color.white};
  }
`;

export default Dot;

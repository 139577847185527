import React from 'react';
import { useI18n } from '@jarvis/react-portal-addons';
import Tooltip from '@veneer/core/dist/scripts/tooltip';
import IconHelp from '@veneer/core/dist/scripts/icons/icon_help';
import { Container } from './styles';

const PlatformNotSupported = () => {
  const { t } = useI18n();

  return (
    <Container>
      {t('printers.supplies.noDataAvailable')}
      <Tooltip
        arrow
        content={t('printers.supplies.oldPrinterTooltip')}
        id="old-printer-tooltip"
        useJsPositioning
      >
        <IconHelp color="colorHpBlue6" data-testid="old-printer-help-icon" />
      </Tooltip>
    </Container>
  );
};

export default PlatformNotSupported;

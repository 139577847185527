import styled from 'styled-components';
import primitives from '@veneer/primitives';
import {
  LevelStates,
  SupplyColors,
  SupplyStates,
} from '../constants';

const borderMapping = {
  Error: '1px dashed #BE1313', // red
  None: '1px solid #F3F3F3', // background
  Warning: '1px solid #D06702', // orange
};

const borders = {
  [SupplyStates.error]: 'Error',
  [SupplyStates.inform]: 'None',
  [SupplyStates.ok]: 'None',
  [SupplyStates.warning]: 'Warning',
};

const levelForState = {
  [LevelStates.depleted]: 0,
  [LevelStates.fulfillment]: 0,
  [LevelStates.low]: 20,
  [LevelStates.ok]: 100,
  [LevelStates.unknown]: 0,
  [LevelStates.veryLow]: 10,
  [LevelStates.veryVeryLow]: 10,
};

const getLevel = (level, state) => {
  if (level === undefined && state) {
    return levelForState[state] || 0;
  }
  return level;
};

const getCartridgeLevelHeight = (level, state) => {
  const theLevel = getLevel(level, state);
  const normalizedLevel = Math.ceil(theLevel / 10) * 10;
  if (normalizedLevel > 99) {
    return 109;
  }
  if (normalizedLevel < 1) {
    return 0;
  }
  return normalizedLevel + 4.5;
};

const getBorder = state => borderMapping[borders[state]] || borderMapping.None;

export const CartridgeContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${primitives.layout.size2}px;
  width: ${primitives.layout.size6}px;
`;

export const CartridgeInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  &.tricolor {
    display: grid;
    grid-gap: 2px;

    .cartridgeDot {
      &.yellow {
        grid-area: 1 / 1 / 2 / 3;
        margin: 0 auto;
      }
      &.cyan {
        grid-area: 2 / 1 / 3 / 2;
      }
      &.magenta {
        grid-area: 2 / 2 / 3 / 3
      }
    }
  }
`;

export const Cartridge = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  height: 100px;
  border-radius: ${primitives.layout.size2}px;
  border: ${({ supplyState }) => getBorder(supplyState)};

  &.tricolor {
    .cartridgeBar {
      width: ${primitives.layout.size2}px;
      &.cyan {
        left: -1px;
      }
      &.yellow {
        left: 7px;
      }
      &.magenta {
        left: 15px;
      }
    }
    .cartridgeLevel {
      width: 100%;
    }
  }

  .cartridgeBar {
    width: 100%;
  }

  .cartridgeLevel {
    width: 100%;
    height: ${({ level, levelState }) => getCartridgeLevelHeight(level, levelState)}px;
  }
`;

export const CartridgeBar = styled.div`
  display: block;
  position: absolute;
  background-color: #f3f3f3;
  height: 100px;

  &.cyan .cartridgeLevel {
    background-color: ${SupplyColors.CYAN};
  }
  &.magenta .cartridgeLevel {
    background-color: ${SupplyColors.MAGENTA};
  }
  &.yellow .cartridgeLevel {
    background-color: ${SupplyColors.YELLOW};
  }
  &.black .cartridgeLevel {
    background-color: ${SupplyColors.BLACK};
  }
`;

export const CartridgeLevel = styled.div`
  position: absolute;
  bottom: 0;
`;

export const CartridgeLevelGrad = styled.div`
  height: 3px;
  width: 100%;
  opacity: ${({ grad }) => 1 - grad};
  background-color: ${primitives.color.white};
`;

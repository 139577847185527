import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  display: flex;
  gap: ${primitives.layout.size2}px;
`;

export default {
  Container,
};
